import {type LinkProps as NextLinkProps} from 'next/link'
import Skeleton from 'react-loading-skeleton'
import {LinkWrapper} from '~/design-system/foundations'

import {
  HgAspectRatio,
  HgHoverCard,
  HgIcon,
  HgTagGroup,
  type HgHoverCardProps,
  type HgIconType,
  type HgTagProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgIndexTableBodyCellContentProps = {
  isLoading?: boolean
  title?: React.ReactNode
  body?: React.ReactNode
  iconType?: HgIconType
  image?: React.ReactNode
  tags?: HgTagProps[]
  hoverCardProps?: Omit<HgHoverCardProps, 'trigger'> & {
    href: NextLinkProps['href']
    analyticsEventName?: string
  }
  className?: string
}

const IconCell = ({
  iconType,
  className,
}: {
  iconType: HgIconType
  className?: string
}) => {
  return (
    <div className={cn('flex h-full w-full items-center', className)}>
      <HgIcon
        size="small"
        iconType={iconType}
        className="text-icon-default md:hidden"
      />
      <HgIcon
        size="regular"
        iconType={iconType}
        className="hidden text-icon-default md:block lg:hidden"
      />
      <HgIcon
        size="large"
        iconType={iconType}
        className="hidden text-icon-default lg:block"
      />
    </div>
  )
}

const TagCell = ({tags, className}: {tags: HgTagProps[]; className?: string}) => {
  return (
    <div className={cn('flex h-full w-full items-center', className)}>
      <HgTagGroup canWrap={false} tags={tags} />
    </div>
  )
}

const TextContentCell = ({
  title,
  body,
  image,
  className,
}: {
  title: React.ReactNode
  body: React.ReactNode
  image: React.ReactNode
  className?: string
}) => (
  <div
    className={cn(
      'flex h-full w-full flex-row items-center space-x-s2 overflow-hidden',
      className
    )}
  >
    {image && (
      <HgAspectRatio
        aspectRatio="1:1"
        classNames={{
          wrapper:
            'flex h-full max-h-40 min-h-40 w-full min-w-40 max-w-40 md:max-h-48 md:min-h-48 md:min-w-48 md:max-w-48 lg:max-h-56 lg:min-h-56 lg:min-w-56 lg:max-w-56 [&>*]:h-full [&_svg]:h-full',
        }}
      >
        {image}
      </HgAspectRatio>
    )}
    <div className="flex flex-col items-start justify-items-center">
      {title && (
        <p className="flex flex-1 items-center text-text-default arcadia-heading-3">
          {title}
        </p>
      )}
      {body && (
        <p className="flex flex-1 items-center text-text-default arcadia-body-2">
          {body}
        </p>
      )}
    </div>
  </div>
)

export const HgIndexTableBodyCell = ({
  isLoading,
  title,
  body,
  iconType,
  image,
  tags,
  hoverCardProps,
  className,
}: HgIndexTableBodyCellContentProps) => {
  if (isLoading) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <Skeleton containerClassName="flex-1" />
      </div>
    )
  }

  const getCellContent = () => {
    if (iconType) {
      return <IconCell iconType={iconType} className={className} />
    }

    if (tags) {
      return <TagCell tags={tags} className={className} />
    }

    return (
      <TextContentCell
        title={title}
        body={body}
        image={image}
        className={className}
      />
    )
  }

  if (hoverCardProps) {
    return (
      <HgHoverCard
        {...hoverCardProps}
        trigger={
          <LinkWrapper
            className="relative z-[1] rounded outline-2 outline-offset-4 outline-border-focus focus-visible:outline"
            href={hoverCardProps.href}
            data-analytics-event-name={hoverCardProps.analyticsEventName}
          >
            {getCellContent()}
          </LinkWrapper>
        }
      />
    )
  }

  return getCellContent()
}
